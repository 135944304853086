import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserForm = _resolveComponent("UserForm")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    modelValue: _ctx.dialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialog) = $event))
  }, {
    header: _withCtx(() => [
      _createElementVNode("h2", null, _toDisplayString(this.updateMode ? `Edit` : `Add New`) + " User", 1)
    ]),
    body: _withCtx(() => [
      _createVNode(_component_UserForm, {
        onFormSubmitted: _ctx.handleFormSubmitted,
        onFormCancel: _ctx.handleFormCancel,
        user: _ctx.selectedUser,
        ref: "userForm"
      }, null, 8, ["onFormSubmitted", "onFormCancel", "user"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}