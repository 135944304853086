
import { defineComponent } from 'vue';
import Pagination from '@/components/Pagination.vue';

export default defineComponent({
  props: {
    headers: { type: Array, required: true },
    items: { type: Array, default: () => [] },
    page: { type: Number, default: 1 },
    totalPages: { type: Number, default: 0 },
    perPage: { type: Number, default: 15 },
    maxVisibleButtons: { type: Number, default: 3 },
    loading: { type: Boolean, default: false },
  },
  components: { Pagination },
  emits: ['on-page-changed'],
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(value) {
        this.$emit('on-page-changed', value);
      },
    },
  },
  methods: {
    align(alignment) {
      if (alignment == 'end') return 'text-end';
      return '';
    },
    changePage(page) {
      this.currentPage = page;
    },
  },
});
