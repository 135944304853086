
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      return this.currentPage - 1;
    },
    pages() {
      const range = [];
      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        (range as any).push({ name: i, isDisabled: i === this.currentPage });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  emits: ['on-changed'],
  methods: {
    onClickPreviousPage() {
      if (this.isInFirstPage) {
        return;
      }
      this.$emit('on-changed', this.currentPage - 1);
    },
    onClickPage(page) {
      if (this.currentPage === page) {
        return;
      }
      this.$emit('on-changed', page);
    },
    onClickNextPage() {
      if (this.isInLastPage) {
        return;
      }
      this.$emit('on-changed', this.currentPage + 1);
    },
  },
});
