import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e84e41"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datatable-container" }
const _hoisted_2 = {
  class: "table align-middle table-row-dashed fs-6 gy-5",
  id: "kt_table_users"
}
const _hoisted_3 = { class: "text-start text-muted fw-bolder fs-7 gs-0" }
const _hoisted_4 = { class: "text-gray-600 fw-bold" }
const _hoisted_5 = ["align"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (column, index) => {
            return (_openBlock(), _createElementBlock("th", {
              class: "min-w-125px",
              key: index
            }, _toDisplayString(column.text), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("tbody", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: item.id
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (column) => {
              return (_openBlock(), _createElementBlock("td", {
                align: item.align,
                key: column.value,
                class: _normalizeClass(`${_ctx.align(
              column.align
            )} padding-top:5px!important;padding-bottom:5px!important`)
              }, [
                _renderSlot(_ctx.$slots, column.value, _normalizeProps(_guardReactiveProps({ item: item })), () => [
                  _createTextVNode(_toDisplayString(item[column.value]), 1)
                ], true)
              ], 10, _hoisted_5))
            }), 128))
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_Pagination, {
      onOnChanged: _ctx.changePage,
      "max-visible-buttons": _ctx.maxVisibleButtons,
      "total-pages": _ctx.totalPages,
      "per-page": _ctx.perPage,
      "current-page": _ctx.currentPage
    }, null, 8, ["onOnChanged", "max-visible-buttons", "total-pages", "per-page", "current-page"])
  ]))
}